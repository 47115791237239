import {
    selectIsLoggedIn,
    AdminLoginCallback,
    selectIsAccessTokenExpired,
    KeyCloakProtectedRoute
} from '@thriveglobal/thrive-web-auth-core'
import {
    useTheme,
    ThriveFullscreenLoading
} from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CircularProgress, ThemeProvider } from '@mui/material'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

const Home = lazy(() => import('../pages/Home'))
const User = lazy(() => import('../pages/UserView/UserView'))

export enum ROUTES {
    HOME = '/user-management',
    USER = '/user-management/:id',
    LOGIN_CALLBACK = '/login/callback'
}

const Router: React.FC = () => {
    const theme = useTheme()
    const authState = useAppSelector((state) => state.auth)
    const isLoggedIn = selectIsLoggedIn(authState)
    const accessTokenExpired = selectIsAccessTokenExpired(authState)
    if (!isLoggedIn || accessTokenExpired) {
        return (
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Suspense fallback={<CircularProgress />}>
                        <Route
                            path={ROUTES.LOGIN_CALLBACK}
                            component={AdminLoginCallback}
                        />
                    </Suspense>
                </BrowserRouter>
                <ThriveFullscreenLoading />
            </ThemeProvider>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <KeyCloakProtectedRoute
                            path={ROUTES.HOME}
                            component={Home}
                            exact
                        />
                        <KeyCloakProtectedRoute
                            path={ROUTES.USER}
                            component={User}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
