import { User } from '../graphql/generated/autogenerated'
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

export enum SearchType {
    ID = 'id',
    EMAIL = 'email'
}
export interface SearchState {
    search: string
    searchBy: SearchType
    searchResult: Partial<User>
}

const initialState: SearchState = {
    search: '',
    searchBy: SearchType.ID,
    searchResult: {}
}

export const userManagementSlice: Slice = createSlice({
    name: 'userManagement',
    initialState,
    reducers: {
        setSearch: (state, { payload }: PayloadAction<string>) => {
            state.search = payload
        },
        setSearchBy: (state, { payload }: PayloadAction<SearchType>) => {
            state.searchBy = payload
        },
        setSearchResult: (state, { payload }: PayloadAction<Partial<User>>) => {
            state.searchResult = payload
        }
    }
})

export const { setSearch, setSearchBy, setSearchResult } =
    userManagementSlice.actions

export default userManagementSlice.reducer
